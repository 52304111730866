import React from "react";
import { graphql } from "gatsby";
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import {
  setPlusMember
} from "../state/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faHandPointUp } from '@fortawesome/free-regular-svg-icons';
import { FormattedMessage, injectIntl} from 'react-intl';
import AppConfig from '../appconfig';


class SuccessContent extends React.Component {
    constructor(props) {
        super(props);
        
        this.updateAndGetUserInfo = this.updateAndGetUserInfo.bind(this);
    }

    componentDidMount() {
        this.updateAndGetUserInfo();
    }

    updateAndGetUserInfo() {
        let userId = localStorage.getItem('userId');
        let sessionId = localStorage.getItem('sessionId');

        if (userId == undefined || userId == '' || 
            sessionId == undefined || sessionId == '' ||
            !this.props.loggedIn) {
            // Not logged in.
            return false;
        }

        let url = AppConfig.API_URL + 'updateAndGetUserInfo';

        url += ('/' + userId);
        url += ('/' + sessionId);

        fetch(url, {
            credentials: 'include'
        })
        .then(res => res.json())
        .then(
            result => {
                if (result.success) {
                    this.props.setPlusMember(result.plusMember);
                } else if (result.errorCode == 101) {
                    window.location.reload();
                }
            },
            error => {
                // Error
            }
        );

        return false;       
    }


    render() {
        return (
            <div className="chatPageWrapper page success">
                <div className="errorText">
                    <FormattedMessage id="congratsPlusMember" />
                     <br /><FontAwesomeIcon icon={faCheckCircle} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn } = state
    return { loggedIn: loggedIn }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setPlusMember: setPlusMember
    },
    dispatch
    );
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SuccessContent));
