import React from "react";
import { graphql } from "gatsby";
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import {
  setPlusMember
} from "../state/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faHandPointUp } from '@fortawesome/free-regular-svg-icons';
import { FormattedMessage, injectIntl} from 'react-intl';
import AppConfig from '../appconfig';
import SuccessContentInner from './successContentInner'


class SuccessContent extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {

        if (!this.props.loggedIn) {
            return (
                <div className="chatPageWrapper page">
                    <div className="errorText">
                        <FormattedMessage id="loginOrChooseNme" />
                        <br /><FontAwesomeIcon icon={faHandPointUp} />
                    </div>
                </div>
            );

        } else {
            return (
                <SuccessContentInner />
            );
        } 
        
    }
}

function mapStateToProps(state) {
    const { loggedIn } = state
    return { loggedIn: loggedIn }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setPlusMember: setPlusMember
    },
    dispatch
    );
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SuccessContent));
